.form {
  display: flex;
  max-width: 700px;
  flex-direction: column;
  padding: 40px 50px;
  border-radius: 6px;
  background: $white;
}

.form__error {
  font-size: 0.875rem;
  color: $red;
  margin-top: 4px;
}

.form__label {
  font-weight: 500;
  margin-bottom: 20px;
}

.form__label-name {
  margin-bottom: 8px;
  font-weight: 600;
  letter-spacing: 0.03rem;
  font-size: 0.875rem;
}

.form__label-input {
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  border: 1px solid $grey;
  input {
    flex-grow: 1;
    padding: 12px;
  }
}

.form__label-textarea {
  textarea {
    padding: 12px;
    resize: none;
    width: 100%;
    height: 200px;
  }
}

.form__label-input-type {
  align-items: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.form__title {
  margin-bottom: 10px;
}
