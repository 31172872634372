.userToolsDropdown {
	position: relative;
	align-self: stretch;
}

.userToolsDropdown__label {
	width: 150px;
	padding: 13px 0;
	border-radius: 4px;
	background: $blue;
	font-weight: 600;
	color: $white;
}

.userToolsDropdown__menu {
	background: $white;
	width: 250px;
	position: absolute;
	top: calc(100% + 10px);
	right: 0;
	z-index: 100;
	display: flex;
	flex-direction: column;
	padding: 8px 0;
	border-radius: 4px;
	box-shadow: 0 0 16px rgba(rgb(185, 185, 185), 0.2);
}

.userToolsDropdown__item {
	padding: 8px 12px;
	text-align: left;
	&:hover {
		background: $blue;
		color: $white;
	}
}

.user__item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.user__item-name {
	display: flex;
	gap: 7px;
}

.user__item-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms linear;
}

.user__item-info {
  flex-grow: 1;
  padding: 12px 16px;
  border-radius: 4px;
  background: $white;
  gap: 10px;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: $dark-1;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.user__item-tools {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user {
	margin: 0 auto;
	max-width: 500px;
	background: #fff;
	border-radius: 6px;
	padding: 20px 25px;
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.user__title {
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 3px;
	letter-spacing: 0.03rem;
}

.user__value {
	font-weight: 600;
	font-size: 1.125rem;
	letter-spacing: 0.03rem;
}