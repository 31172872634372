/* login */
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__input {
  width: 380px;
}

.login__title {
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
}

.login__submit {
  padding: 12px;
  line-height: 16px;
  background: $main;
  color: $white;
  border-radius: 4px;
  letter-spacing: 0.03rem;
  &:hover {
    background: darken($main, 4%);
  }
  &:active {
    background: darken($main, 7%);
  }
}

.login__forget-password {
  font-size: 0.875rem;
  margin-top: 5px;
  cursor: pointer;
  &:hover {
    color: $main;
  }
  &:active {
    color: darken($main, 4%);
  }
}
