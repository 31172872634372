.products__search {
  flex-grow: 1;
}

.products__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.products__tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}


.main-product-image {
  height: 130px;
}

.product-images {
  height: 130px;
}

.flex-wrap {
  flex-wrap: wrap;
}
