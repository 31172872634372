$light-0: #efefef;
$light-1: #f5f5f5;
$light-2: #f1f1f1;
$light-3: #bebebe;
$grey: #858585;
$dark-1: #535353;
$main: #3974b9;
$main-light-0: #ecf3f7;
$main-light-1: #dae9f0;
$white: #ffffff;
$red: #e76f6f;
$blue: #3974b9;
$green: #46a34a;
$orange: #fcb64e;
