.search {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: $white;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid $white;
  transition: 100ms linear;
}

.search_status_focus {
  border-color: $main-light-1;
}

.search__icon {
  margin-right: 6px;
  color: $dark-1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__input {
  font-size: 0.875rem;
  padding: 15px 0;
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.05rem;

  &::placeholder {
    color: $light-3;
  }
}
