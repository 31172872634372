.navigation {
  width: $navigation-width;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: calc($header-height + 30px);
  max-height: calc(100vh - $header-height - 30px);
  overflow-y: auto;
  padding: 0 20px 50px 0;
}

.navigation_disabled {
  pointer-events: none;
}
